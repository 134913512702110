var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booking)?_c('div',{staticClass:"notification-booking-overview"},[_c('section',{staticClass:"notification-booking-overview__schedule"},[_c('i',{staticClass:"fa-solid fa-clock fa-sm"}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.booking.slot_start,'H:mm', _vm.timezone))+" – "+_vm._s(_vm._f("moment")(_vm.booking.slot_end,'H:mm', _vm.timezone))+" ")])]),(_vm.booking.space)?_c('section',{staticClass:"notification-booking-overview-details"},[(
        _vm.booking.space.space_images &&
        _vm.booking.space.space_images[0] &&
        _vm.booking.space.space_images[0].image_url
      )?_c('img',{staticClass:"notification-booking-overview-details__image",attrs:{"src":_vm.booking.space.space_images[0].image_url,"alt":_vm.booking.space.internal_name,"loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"notification-booking-overview-details__info"},[_c('p',[_vm._v(_vm._s(_vm.booking.space.internal_name))]),_c('span',[_vm._v(_vm._s(_vm.booking.space.space_description))])]),_c('router-link',{attrs:{"tabindex":"-1","to":_vm.useGetLocalizedPath(((_vm.BookingRoutes.BOOKINGS) + "/" + (_vm.booking.booking_id)))}},[_c('WMButton',{attrs:{"tabindex":"0","small":"","icon-class-name":"fa-solid fa-list fa-sm"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t('notifications.day_overview.details.actions.booking'))+" ")])])],1)],1):_vm._e(),(_vm.options)?_c('ListDropdown',{staticClass:"notification-booking-overview__dropdown",scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var expanded = ref.expanded;
return [_c('p',{staticClass:"notification-booking-overview__dropdown-title"},[_vm._v(" "+_vm._s(_vm.$tc('notifications.day_overview.details.title', !expanded))+" ")])]}},{key:"items",fn:function(){return [_c('ul',{staticClass:"notification-booking-overview__list"},[(_vm.options.configuration)?_c('ListItem',{staticClass:"notification-booking-overview__list-item",attrs:{"icon-class":"fa-solid fa-chair fa-sm","title":_vm.$t(
              ("booking_summary.configurations." + (_vm.options.configuration.configuration_type.toLowerCase()) + ".title")
            )}}):_vm._e(),_c('ListItem',{staticClass:"notification-booking-overview__list-item",attrs:{"icon-class":"fa-solid fa-person-half-dress fa-sm","title":_vm.$tc('booking_summary.attendees', _vm.booking.attendees)}})],1),_vm._l((_vm.options.facilities),function(services,key){return _c('div',{key:services.reference_id},[_c('p',{staticClass:"notification-booking-overview-services__title"},[_vm._v(" "+_vm._s(_vm.$t( ("notifications.day_overview.details.services." + (key.toLowerCase())) ))+" ")]),_c('ul',{key:key,staticClass:"notification-booking-overview-services__list"},[_vm._l((services),function(item){return [(item)?_c('ListItem',{key:item.service_type,attrs:{"icon-class":("fa-solid fa-" + (_vm.$t(
                ("booking_summary." + (item.service_type.toLowerCase()) + "." + (item.service_name) + ".icon")
              )) + " fa-xs"),"title":_vm.$tc(
                  ("booking_summary." + (item.service_type.toLowerCase()) + "." + (item.service_name) + ".title"),
                  item.quantity
                )}}):_vm._e()]})],2)])}),(_vm.options.customs && _vm.options.customs.length)?[_c('p',{staticClass:"notification-booking-overview-services__title"},[_vm._v(" "+_vm._s(_vm.$t("notifications.day_overview.details.services.custom"))+" ")]),_c('ul',{staticClass:"notification-booking-overview-services__list"},[_vm._l((_vm.options.customs),function(item){return [(item)?_c('ListItem',{key:item.order_item_id,attrs:{"title":_vm.$tc(
                  'notifications.day_overview.details.services.item',
                  item.quantity,
                  { name: item.order_item_name }
                )}}):_vm._e()]})],2)]:_vm._e()]},proxy:true}],null,false,3467587695)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }