import { computed, defineComponent } from '@vue/composition-api';
import ListItem from '@/uikit/ListItem.vue';
import { BookingRoutes } from '@/Booking/booking.const';
import { getBookingOptions, getFacilities } from '@/Booking/utils/bookings';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import ListDropdown from '@/uikit/ListDropdown.vue';
export default defineComponent({
    components: {
        ListDropdown,
        ListItem
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const options = computed(() => {
            const bookingOptions = getBookingOptions(props.booking);
            return {
                configuration: bookingOptions?.configurations?.[0],
                facilities: getFacilities(bookingOptions?.services),
                customs: bookingOptions?.customs
            };
        });
        return {
            options,
            BookingRoutes,
            timezone: globalThis.$timezone,
            useGetLocalizedPath
        };
    }
});
