import { computed, defineComponent } from '@vue/composition-api';
import NotificationsVenue from '@/Notifications/components/NotificationsVenue.vue';
import Access from '@/components/Access.vue';
import { useClearNotificationsMutation, UserRoleState } from '@/generated-types/graphql.types';
import VendorNotificationsProvider from '@/Notifications/components/VendorNotificationsProvider';
import CustomerNotificationsProvider from '@/Notifications/components/CustomerNotificationsProvider.vue';
import NotificationEventsList from '@/Notifications/NotificationEventsList.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        NotificationsVenue,
        VendorNotificationsProvider,
        CustomerNotificationsProvider,
        NotificationEventsList,
        Access
    },
    setup(_, context) {
        const { root } = context;
        const venueId = computed({
            get() {
                return +root.$route.params.venueId;
            },
            set(venueId) {
                globalThis.$router.push({ params: { venueId } });
            }
        });
        const { mutate: clearAllNotifications } = useClearNotificationsMutation();
        const clearNotifications = (venueId) => {
            clearAllNotifications({ venueId });
        };
        return {
            roles: UserRoleState,
            venueId,
            clearNotifications,
            useGetLocalizedPath
        };
    }
});
