import { computed, defineComponent } from '@vue/composition-api';
import { EventNotificationName } from '@/generated-types/graphql.types';
import BookingStatusLabel from '@/Booking/components/BookingStatusLabel.vue';
import useUserRoles from '@/shared/composables/useUserRoles';
import BookingTimelineAction from '@/Booking/components/BookingTimelineAction.vue';
import { BookingRoutes } from '@/Booking/booking.const';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import isFunction from 'lodash/isFunction';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const actionsLinks = {
    default: params => params?.booking
        ? `${BookingRoutes.BOOKINGS}/${params.booking.booking_id}`
        : '#',
    [EventNotificationName.FirstVenueCreated]: 'vendor/onboarding/venue/details',
    [EventNotificationName.FirstSpaceCreated]: params => ({
        name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN,
        params: { venueId: params?.venue_id?.toString() ?? '' }
    }),
    [EventNotificationName.OrganisationBanned]: 'chat',
    [EventNotificationName.PayoutDetailsSubmitted]: 'profile/payout',
    [EventNotificationName.TeamMembershipInvitation]: 'profile/account',
    [EventNotificationName.UpdateLocationDetails]: params => params?.space ? `vendor/venue/${params.space.venue.venue_id}/details` : '#',
    [EventNotificationName.UpdateOrganisationDetails]: 'profile/organisation',
    [EventNotificationName.UpdateProfile]: 'profile/account',
    [EventNotificationName.UpdateSpaceDetails]: params => params?.space
        ? `vendor/venue/${params.space.venue.venue_id}/space/${params.space.space_id}/details`
        : '#',
    [EventNotificationName.SpaceActivated]: params => params?.space
        ? `vendor/venue/${params.space.venue.venue_id}/space/${params.space.space_id}/details?status=true`
        : '#',
    [EventNotificationName.SpaceAgendaIsNotSynchronized]: params => params?.space
        ? `${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}/${params.space.venue.venue_id}?isAgendaModalOpened=true`
        : '#'
};
export default defineComponent({
    components: {
        BookingStatusLabel,
        BookingTimelineAction
    },
    props: {
        value: { type: Object, default: null }
    },
    setup(props, context) {
        const { root } = context;
        const roles = useUserRoles();
        const user = computed(() => {
            const bookerFirstName = props.value?.booking?.order?.booker.first_name;
            const bookerLastName = props.value?.booking?.order?.booker.last_name;
            let bookerFullName = '';
            if (bookerFirstName && bookerLastName) {
                bookerFullName = bookerFirstName.concat(' ', bookerLastName);
            }
            const vendorFirstName = props.value?.booking?.order?.vendor.first_name;
            const vendorLastName = props.value?.booking?.order?.vendor.last_name;
            let vendorFullName = '';
            if (vendorFirstName && vendorLastName) {
                vendorFullName = vendorFirstName.concat(' ', vendorLastName);
            }
            return roles.value.VENDOR ? bookerFullName : vendorFullName;
        });
        const notificationText = computed(() => {
            let eventName = props.value?.event_name;
            if (props.value?.booking?.is_afterpay_requested &&
                eventName === EventNotificationName.BookingUnpaid) {
                eventName = 'service_fee_unpaid';
            }
            let text = root.$tc(`notifications.action.type.${eventName}`, +roles.value.VENDOR, {
                user: user.value,
                space: props.value.space ? props.value.space.internal_name : null
            });
            if (props.value?.booking?.is_discount_applied) {
                text = text.concat(' ', root.$t('notifications.action.type.discount').toString());
            }
            if (props.value?.booking?.is_afterpay_requested &&
                eventName === EventNotificationName.BookingRequestCreatedForHost) {
                text = text.concat(' ', root.$t('notifications.action.type.afterpay').toString());
            }
            return text;
        });
        const generateUrl = () => {
            let link = (actionsLinks[props.value.event_name] &&
                isFunction(actionsLinks[props.value.event_name])
                ? actionsLinks[props.value.event_name](props.value)
                : actionsLinks[props.value.event_name]) ||
                actionsLinks.default(props.value);
            if (link.name) {
                link.name = useGetLocalizedPath(link.name);
            }
            else {
                link = useGetLocalizedPath(link);
            }
            return link;
        };
        return {
            notificationText,
            roles,
            EventNotificationName,
            generateUrl
        };
    }
});
