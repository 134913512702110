var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.actions && _vm.actions.length)?_c('header',{staticClass:"notification-events-header"},[_c('div',{staticClass:"notification-events-header__title"},[_c('h3',[_vm._v(_vm._s(_vm.$t('notifications.overview.list.actions.title')))]),(_vm.actions.length)?_c('span',{staticClass:"notification-events-header__counter"},[_vm._v(_vm._s(_vm.actions.length))]):_vm._e()])]):_vm._e(),(_vm.actions && _vm.actions.length)?_c('div',{staticClass:"notification-events__list"},_vm._l((_vm.actions),function(action){return _c('ActionNotification',{key:action.event_notification_id,attrs:{"value":action}})}),1):_vm._e(),(
      (_vm.notifications && _vm.notifications.length) ||
      (_vm.dayOverview && _vm.dayOverview.day_overview_bookings.length)
    )?_c('header',{staticClass:"notification-events-header"},[_c('div',{staticClass:"notification-events-header__title"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('notifications.overview.list.notifications.title'))+" ")]),(_vm.unreadNotifications)?_c('span',{staticClass:"notification-events-header__counter"},[_vm._v(_vm._s(_vm.unreadNotifications))]):_vm._e()]),_c('WMButton',{staticClass:"notification-events-header__clear",attrs:{"ghost":"","borderless":"","icon-class-name":"fa-solid fa-trash fa-sm"},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('notifications.overview.list.notifications.clear')))])])],1):_vm._e(),((_vm.notifications && _vm.notifications.length) || _vm.dayOverview)?_c('div',{staticClass:"notification-events__list"},[_c('Access',{attrs:{"role":_vm.roles.Vendor}},[(
          _vm.dayOverview &&
          _vm.dayOverview.day_overview_bookings &&
          _vm.dayOverview.day_overview_bookings.length
        )?_c('Notification',{attrs:{"value":_vm.dayOverview},on:{"read":_vm.readNotification}},[_c('DayOverview',{attrs:{"value":_vm.dayOverview}})],1):_vm._e()],1),_vm._l((_vm.notifications),function(notification){return _c('Notification',{key:notification.event_notification_id,attrs:{"value":notification},on:{"remove":_vm.removeNotification,"read":_vm.readNotification}})})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }