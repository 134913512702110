import { computed, defineComponent } from '@vue/composition-api';
import { getBookingTimeline } from '@/Booking/utils/bookings';
import useUserRoles from '@/shared/composables/useUserRoles';
import DeadlineCountdownTimer from '@/components/DeadlineCountdownTimer.vue';
export default defineComponent({
    components: {
        DeadlineCountdownTimer
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const roles = useUserRoles();
        const timeline = computed(() => getBookingTimeline(props.booking, roles.value.VENDOR));
        const action = computed(() => {
            const [type, deadline] = timeline.value
                ? Object.entries(timeline.value).filter(([_, value]) => typeof value === 'number')[0] ?? []
                : [];
            return {
                type,
                deadline
            };
        });
        return {
            action,
            timeline
        };
    }
});
