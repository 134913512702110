import { defineComponent } from '@vue/composition-api';
import NotificationBookingOverview from '@/Notifications/components/NotificationBookingOverview.vue';
import Label from '@/uikit/Label.vue';
export default defineComponent({
    components: {
        NotificationBookingOverview,
        Label
    },
    props: {
        value: {
            type: Object,
            default: null
        }
    }
});
