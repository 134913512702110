import { defineComponent, computed } from '@vue/composition-api';
import debounce from 'lodash/debounce';
import { unixFormatted } from '@/util/dates';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
export default defineComponent({
    props: {
        value: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, { emit }) {
        const readNotification = () => {
            if (!props.value?.is_read)
                emit('read', props.value);
        };
        const redirectToBooking = () => {
            if (props.value?.booking) {
                globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${props.value.booking.booking_id}`));
            }
        };
        const bookingDate = computed(() => unixFormatted(props.value?.booking?.slot_start || 0, 'll'));
        return {
            bookingDate,
            readNotification: debounce(readNotification, 500),
            readNotificationOnHover: readNotification,
            redirectToBooking
        };
    }
});
