import { computed, defineComponent } from '@vue/composition-api';
import { EventNotificationName, useCustomerNotificationsQuery } from '@/generated-types/graphql.types';
import groupBy from 'lodash/groupBy';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
export default defineComponent({
    components: {
        PageLoadingBar
    },
    setup(_, context) {
        const { emit } = context;
        const { result, onResult, loading } = useCustomerNotificationsQuery({
            fetchPolicy: 'network-only'
        });
        const events = computed(() => result.value?.get_notifications?.filter(notification => notification && !notification.is_deleted));
        const data = computed(() => {
            const notifications = groupBy(events.value, event => event?.event_type);
            return {
                ...notifications,
                NOTIFICATION: notifications.NOTIFICATION?.filter(notification => notification?.event_name !== EventNotificationName.DayOverview)
            };
        });
        onResult(() => {
            emit('loaded', {
                notifications: data.value.NOTIFICATION,
                actions: data.value.ACTION
            });
        });
        return {
            data,
            loading
        };
    }
});
