var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[_c('Access',{attrs:{"role":_vm.roles.Vendor}},[_c('NotificationsVenue',{model:{value:(_vm.venueId),callback:function ($$v) {_vm.venueId=$$v},expression:"venueId"}})],1),_c('Access',{attrs:{"role":_vm.roles.Vendor}},[_c('VendorNotificationsProvider',{attrs:{"venue-id":_vm.venueId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
var actions = ref.actions;
var dayOverview = ref.dayOverview;
return [_c('NotificationEventsList',{attrs:{"day-overview":dayOverview,"notifications":notifications,"actions":actions},on:{"clear":function($event){return _vm.clearNotifications(_vm.venueId)}}})]}}])})],1),_c('Access',{attrs:{"role":_vm.roles.Booker}},[_c('CustomerNotificationsProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.notifications;
var actions = ref.actions;
return [_c('NotificationEventsList',{attrs:{"notifications":notifications,"actions":actions},on:{"clear":function($event){return _vm.clearNotifications(_vm.venueId)}}})]}}])})],1),_c('div',{staticClass:"manage-notifications"},[_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('profile/notifications')}},[_c('WMButton',{attrs:{"icon-class-name":"fa-solid fa-message fa-xs"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('notifications.manage_notifications.manage_notifications')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }