import { useReadNotificationMutation, useRemoveNotificationMutation, UserRoleState } from '@/generated-types/graphql.types';
import { computed, defineComponent } from '@vue/composition-api';
import Notification from '@/Notifications/components/Notification.vue';
import ActionNotification from '@/Notifications/components/ActionNotification.vue';
import Access from '@/components/Access.vue';
import DayOverview from '@/Notifications/components/DayOverview.vue';
function debounce(func, wait) {
    let timeout;
    let values = [];
    return function (...args) {
        const context = this;
        values.push(...args);
        clearTimeout(timeout);
        timeout = setTimeout(() => func.call(context, values), wait);
    };
}
export default defineComponent({
    components: {
        Notification,
        ActionNotification,
        DayOverview,
        Access
    },
    props: {
        actions: {
            type: Array,
            default: null
        },
        notifications: {
            type: Array,
            default: null
        },
        dayOverview: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const { mutate: removeEventNotification } = useRemoveNotificationMutation({});
        const removeNotification = (notification) => {
            removeEventNotification({ id: notification.event_notification_id });
        };
        const { mutate: readEventNotification } = useReadNotificationMutation({});
        const readNotification = debounce((notifications) => {
            const ids = notifications
                .filter(notification => notification.event_name !== 'day_overview')
                .map(({ event_notification_id }) => event_notification_id);
            readEventNotification({
                notificationId: ids
            });
        }, 200);
        const unreadNotifications = computed(() => {
            const dayOverviewCount = props.dayOverview
                ? Number(!props.dayOverview.is_read &&
                    props.dayOverview.day_overview_bookings?.length)
                : 0;
            return (props.notifications?.filter(notification => !notification.is_read)
                .length + dayOverviewCount);
        });
        return {
            readNotification,
            roles: UserRoleState,
            unreadNotifications,
            removeNotification
        };
    }
});
